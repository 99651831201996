<template>
  <div class="error-page">请在微信公众号中打开</div>
</template>

<style lang="less" scope>
.error-page {
  background: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 15rem;
  font-size: 20px;
}
</style>